import React, { useState } from 'react'
import "./index.css"
import Nav from "../../components/Nav";
import navItems from "../../components/Nav/index.json";
import emailjs from '@emailjs/browser';
import {Link} from 'react-router-dom';
import subnavItems from "./index.json";
import Dossier from "../../pdf/Dossier_de_presse.pdf";
import Statuts from "../../pdf/Statuts.pdf";
import Reglement from "../../pdf/Reglement_interieur.pdf";
import Adhesion from "../../pdf/Adhesion.pdf";
import Cotisations1 from "../../pdf/Cotisations1.pdf";
import Cotisations2 from "../../pdf/Cotisations2.pdf";
import Planning from "../../pdf/Planning.pdf";

function Contact() {


    const [inputValues, setInputValues] = useState({
        nom: '', email: '', demande: ''
    });

    const handleChange = event => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const isMobile = window.matchMedia("(max-width: 45.5em)").matches;

    const handleSubmit = event => {
        event.preventDefault();
        const templateId = "template_83x1fk7";
        const serviceId = "service_bxw1trp";
        sendFeedback(serviceId, templateId, {
            nom: inputValues.nom,
            email: inputValues.email,
            demande: inputValues.demande
        })
        
       alert('Votre message a bien été envoyé');
    }

    const sendFeedback = (serviceId, templateId, variables) => {
        emailjs
        .send(serviceId, templateId, variables, 'kScCoiR4ns_m-TIyv')
        .then((res) => {
            console.log('Success');
        })
        .catch((err) => 
            console.error('Il y a une erreur.')
        );
    };

    return (
        <>
            <Nav items={navItems.urls} />
            <Nav items={subnavItems.urls} sub />
            <div className="Contact-Core">

                <div className="Contact ContactImg" id="maps">
                    { isMobile ? <iframe title="gymnase_casalis" 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.0951198690154!2d2.469751515616367!3d48.78007427927996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e60ca265bfffff%3A0x7dfc380e35fb4762!2s33%20Avenue%20du%20Dr%20Paul%20Casalis%2C%2094000%20Cr%C3%A9teil!5e0!3m2!1sfr!2sfr!4v1678461079980!5m2!1sfr!2sfr" 
                        width="100%" 
                        height="100%" 
                        style={{ border: 0 }}
                        allowFullScreen="" 
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade" 
                    ></iframe> : "" }
                    { !isMobile ? <iframe title="gymnase_casalis" 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.0951198690154!2d2.469751515616367!3d48.78007427927996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e60ca265bfffff%3A0x7dfc380e35fb4762!2s33%20Avenue%20du%20Dr%20Paul%20Casalis%2C%2094000%20Cr%C3%A9teil!5e0!3m2!1sfr!2sfr!4v1678461079980!5m2!1sfr!2sfr" 
                        width="600" 
                        height="450" 
                        style={{ border: 0 }}
                        allowFullScreen="" 
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade" 
                    ></iframe> : "" }
                    <br /><br />
                </div>
                <div className="Contact Contact-Form">
                <p className="Contact-Title" id="informations-utiles">Informations utiles</p>
                <div className='Contact-Text'><p className="Contact-horaires">Nos accueils physiques se font au centre sportif du Docteur Paul Casalis les jours et horaires suivants :</p>
                <ul>
                    <li><span>lundi :</span> 17:00–21:30</li>
                    <li><span>mardi :</span> 17:00–21:30</li>
                    <li><span>mercredi :</span> 17:00–21:30</li>
                    <li><span>jeudi :</span> 17:00–21:30</li>
                    <li><span>vendredi :</span> 17:00–21:30</li>
                    <li><span>samedi :</span> 10:30–12:30 / 15:00-19:30</li>
                    <li><span>dimanche :</span> Fermé</li>
                    <li><strong>Fermé le samedi pendant les vacances scolaires</strong></li>
                </ul>
                <br />
                <p className="Contact-horaires">Nos coordonnées : </p>
                <ul>
                    <li><span>Tél :</span> <a className="Contact-sms" href ="tel:+33604480002">06 04 48 00 02</a> / <a className="Contact-sms" href ="tel:+33617878335">06 17 87 83 35</a></li>
                    <li><span>Email :</span> <a href="mailto:uschmf@yahoo.fr">uschmf@yahoo.fr</a> / <a href="mailto:maison.sport.sante.creteil@gmail.com">maison.sport.sante.creteil@gmail.com</a></li>
                </ul>
                <br />
                <p className="Contact-horaires">Nos permanences téléphoniques se font du lundi au vendredi de 10h à 12h puis de 13h à 17h.<br /><br />
                Nos activités hors les murs se font du lundi au dimanche de 9h à 19h selon les partenariats.</p>
                </div>
                </div>

            </div>
            <div className="Contact-Core">
            <div className="Contact Contact-Form">
                    <p className="Contact-Title" id="nous-contacter">Nous contacter</p>
                    <form method="post" id="contactForm">
                        <label htmlFor="nom">
                            <span>Nom : </span>
                            <input type="text" name="nom" id="nom" onChange={handleChange} />
                        </label>
                        <br />
                        <label htmlFor="email">
                            <br />
                            <span>Votre adresse email : </span>
                            <input type="text" name="email" id="email" onChange={handleChange} />
                        </label>
                        <br />
                        <label htmlFor="demande">
                            <br />
                            <span>Votre demande : </span>
                            <textarea rows="8" cols="50" name="demande" id="demande" onChange={handleChange} />
                        </label>
                        <br />
                        <input className="Button" type="submit" id="submit" onClick={handleSubmit} value="Envoyer" />
                    </form>
                </div>
                <div className="Contact ContactImg"><br /><img alt="Gymnase Paul Casalis de Créteil" src={require('../../images/gymnase_casalis.jpg')} /></div>
                
            </div>
            <div className="Contact-Intro">
                <p className="Contact-Title" id="telechargements">Téléchargements</p>
                    <ul className="Contact-Telechargements">
                        <li><a href={Dossier} download="Dossier_de_presse.pdf"> Dossier de presse</a></li><br />
                        <li><a href={Reglement} download="Reglement_interieur.pdf">Règlement intérieur</a></li><br />
                        <li><a href={Statuts} download="Statuts.pdf">Statuts</a></li><br />
                        <li><a href={Adhesion} download="Adhesion.pdf">Fiche d'adhésion</a></li><br />
                        <li><a href={Cotisations1} download="Cotisation1.pdf">Cotisations</a></li><br />
                        <li><a href={Cotisations2} download="Cotisation2.pdf">Cotisations suite</a></li><br />
                        <li><a href={Planning} download="Planning.pdf">Planning des entrainements</a></li><br />
                    </ul>

            </div>
            <div className="Contact-Core">
                <div className="Contact ContactImg">
                    <img src={require('../../images/map_maisons-sport-sante.png')} alt="Carte des maisons sport-santé du Val de Marne" />
                </div>
                <div className="Contact Contact-Form">
                <p className="Contact-Title" id="mss">Les Maisons Sport-Santé du Val-de-Marne</p>
                <ul className="Contact-mss_corrdonnes">
                    <li><span>MSS Orly (Avenir Sport) :</span> <br /><a className="Contact-sms" href ="tel:+33781566159">07 81 56 61 59</a><br />1 rue du Verger 94310 ORLY <br /><Link target="_blank" to="https://www.avenirsportifdorly.fr">https://www.avenirsportifdorly.fr</Link></li>
                    <li><span>HB Gym Truck Orly :</span> <br /><a className="Contact-sms" href ="tel:+33782096822">07 82 09 68 22</a> <br />33 rue des Hautes Bornes 94310 ORLY<br /><Link target="_blank" to="https://www.hbgymtruck.com">https://www.hbgymtruck.com</Link></li>
                    <li><span>VGA Saint-Maur :</span> <br /><a className="Contact-sms" href ="tel:+33628709217">06 28 70 92 17</a><br />8 avenue du Nord 94100 SAINT-MAUR-DES-FOSSES <br /> <Link target="_blank" to="https://www.vga-fr.org/sport-sante/">https://www.vga-fr.org/sport-sante/</Link></li>
                    <li><span>MSS Hôpitaux de Saint-Maurice :</span> <br /><a className="Contact-sms" href ="tel:+33143966799">01 43 96 67 99</a> <br /> 12-14 rue Val d’Osne 94410 SAINT-MAURICE<br /><Link target="_blank" to="http://www.hopitaux-saint-maurice.fr">https://www.hopitaux-saint-maurice.fr</Link></li>
                    <li><span>Valenton IRMA :</span><br /><a className="Contact-sms" href ="tel:+33145108093">01 45 10 80 93</a> <br /> CHI Villeneuve-Saint- Georges <br />2 rue Émilion Michaut et Lucien Rabeux 94460 VALENTON<br /><Link target="_blank" to="http://www.irma-valenton.fr">https://www.irma-valenton.fr</Link></li>
                    <li><span>MSS de Vincennes  :</span> <br /> <a className="Contact-sms" href ="tel:+33143986642">01 43 98 66 42 / 06 34 46 14 10</a> <br />53 bis rue de Fontenay 94300 VINCENNES<br /> <Link target="_blank" to="https://www.vincennes.fr/">https://www.vincennes.fr/</Link></li>
                </ul>
                <ul className="Contact-mss_corrdonnes">
                    <li><span>MSS de Bry-Villiers :</span> <br /> <a className="Contact-sms" href ="tel:+33149830096">01 49 83 00 96</a> <br /> 6/8 rue Entroncemento, CRF 94350 VILLIERS-SUR-MARNE<br /><Link target="_blank" to="https://www.crf-villiers.org/le-centre/">https://www.crf-villiers.org/le-centre/</Link></li>
                    <li><span>MSS de Champigny-sur-Marne :</span> <br /><a className="Contact-sms" href ="tel:+33620438419">06 20 43 84 19</a> <br /><Link target="_blank" to="https://www.champigny94.fr/article/sport-sante-dans-ma-ville">https://www.champigny94.fr/article/sport-sante-dans-ma-ville</Link></li>
                    <li><span>MSS de Chevilly-la-Rue (Pasteur) :</span> <br /> <a className="Contact-sms" href ="tel:+33785555522">07 85 55 55 22</a> <br /> 24 rue Pasteur 94550 CHEVILLY LARUE<br /><Link target="_blank" to="https://www.msp-pasteur.fr">https://www.msp-pasteur.fr</Link></li>
                    <li><span>Maison Sport-Santé UPEC 94 Créteil :</span><br />61 avenue du Général de Gaulle 94010 CRETEIL<br /><Link target="_blank" to="https://www.u-pec.fr/">https://www.u-pec.fr/</Link></li>
                    <li><span>Entente Sportive Caudacienne La Queue en Brie :</span><br /><a className="Contact-sms" href ="tel:+33611741922">06 11 74 19 22</a> <br />Rue du Chemin Vert 94510 LA QUEUE-EN-BRIE<br /><Link target="_blank" to="https://www.escaudacienne.fr/esc/sections/sport-sante/">https://www.escaudacienne.fr/esc/sections/sport-sante/</Link></li>
                    <li><span>GH Paul Guiraud :</span><br /><a className="Contact-sms" href ="tel:+33142117000">01 42 11 70 00</a> <br />54 avenue de la République 94800 VILLEJUIF<br /><Link target="_blank" to="https://www.gh-paulguiraud.fr/">https://www.gh-paulguiraud.fr/</Link></li>
                </ul>
                
                </div>

            </div>

            
            
        </>
    );
}

export default Contact








 






	
	
	
	
	













