import React from 'react'
import Nav from "../../components/Nav";
import navItems from "../../components/Nav/index.json";
import "./index.css"
import {Link} from 'react-router-dom';

function Partenaires() {

    return (
        <>
            <Nav items={navItems.urls} />
            <div className="Partenaires-Core">
                <div className="Partenaires Partenaires-Intro">
                    <p className="Partenaires-Title">Ils nous font confiance</p>
                    <p className="Partenaires-Text">
                    Plusieurs partenaires nous font confiance pour intervenir au sein de leurs structures pour des ateliers de sport-santé au travail, en milieu hospitalier pour des soins de support, pour des actions de prévention primaire auprès de divers publics (collectivités territoriales, structures professionnelles d'insertion, lycées...), pour de la formation, de l'information et de la sensibilisation.
                    De nombreux partenaires institutionnels contribuent directement au financement de notre structure et de ses projets.<br /><br />
                    Grâce à l'ensemble de nos partenaires, les missions de la Maison Sport-Santé de Créteil/Mont-Mesly peuvent se réaliser sur le terrain : informer, orienter, accueillir et accompagner tous les publics, adhérents et bénéficiaires, dans une activité physique et sportive bénéfique pour leur santé.<br /><br />
                    </p>
                </div>
                <p className="Partenaires-Title">Nos partenaires institutionnels</p>
                <div className="Partenaires_Logos">
                    <Link target="_blank" to="https://www.ac-creteil.fr/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/Academie_Creteil.png')} /></Link>
                    <Link target="_blank" to="https://sudestavenir.fr/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/gpsea.jpg')} /></Link>
                    <Link target="_blank" to="https://www.agencedusport.fr/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/ANS.jpg')} /></Link>
                    <Link><img alt="Logo Maisons Sport-Santé" src={require('../../images/partenaires/maison_sport_sante.png')} /></Link>
                    <Link><img alt="Logo_Partenaires" src={require('../../images/partenaires/service_civique.jpg')} /></Link>
                    <Link target="_blank" to="https://www.valdemarne.fr/"> <img alt="Logo_Partenaires" src={require('../../images/partenaires/VDM.png')} /></Link>
                    <Link target="_blank" to="https://www.ville-creteil.fr/"> <img alt="Logo_Partenaires" src={require('../../images/partenaires/ville_de_creteil.png')} /></Link>
                    <Link><img alt="Logo_Partenaires" src={require('../../images/partenaires/PREF_VDM.png')} /></Link>
                    <Link><img alt="Logo_Partenaires" src={require('../../images/partenaires/IMP24.jpg')} /></Link>
                    <Link target="_blank" to="https://www.iledefrance.ars.sante.fr/"> <img alt="Logo_Partenaires" src={require('../../images/partenaires/ARS.jpg')} /></Link>
                    <Link><img alt="Logo_Partenaires" src={require('../../images/partenaires/ministere_sante.png')} /></Link>
                    <Link><img alt="Logo_Partenaires" src={require('../../images/partenaires/ministere_sports.png')} /></Link>
                    <Link target="_blank" to="https://www.associations.gouv.fr/FDVA.html"><img alt="Logo_Partenaires" src={require('../../images/partenaires/FDVA.png')} /></Link>
                    <Link target="_blank" to="https://www.fondationdefrance.org/fr/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/fondation_de_france.jpg')} /></Link>
                    <Link target="_blank" to="https://www.sportspourtous.org/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/FF_Sports_pour_Tous.jpg')} /></Link>
                </div>
                <br /><br />
                <p className="Partenaires-Title">Nos autres partenaires</p>
                <div className="Partenaires_Logos">
                    <Link> <img alt="Logo_Partenaires" src={require('../../images/partenaires/us_creteil_generale.png')} /></Link>
                    <Link target="_blank" to="https://www.ameli.fr/"> <img alt="Logo_Partenaires" src={require('../../images/partenaires/cpam.jpg')} /></Link>
                    <Link target="_blank" to="https://www.cdos94.org/"> <img alt="Logo_Partenaires" src={require('../../images/partenaires/cdos_94.jpg')} /></Link>
                    <Link target="_blank" to="https://www.creditmutuel.fr/home/index.html"><img alt="Logo_Partenaires" src={require('../../images/partenaires/credit_mutuel.jpg')} /></Link>
                    <Link target="_blank" to="https://www.e2c94.com/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/E2C.JPG')} /></Link>
                    <Link><img alt="Logo_Partenaires" src={require('../../images/partenaires/OMS.JPG')} /></Link>
                    <Link target="_blank" to="https://www.prescriforme.fr/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/prescriforme.jpg')} /></Link>   
                    <Link target="_blank" to="https://www.ffhandball.fr/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/ffhb.png')} /></Link>
                    <Link target="_blank" to="https://www.chicreteil.fr/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/chic.jpg')} /></Link> 
                    <Link target="_blank" to="https://www.ffhaltero.fr/"> <img alt="Logo_Partenaires" src={require('../../images/partenaires/FFHM.jpg')} /></Link>
                    <Link target="_blank" to="https://www.revesdiab.fr/"> <img alt="Logo_Partenaires" src={require('../../images/partenaires/revesdiab.jpg')} /></Link>
                    <Link><img alt="Logo_Partenaires" src={require('../../images/partenaires/DAC.jpg')} /></Link>
                    <Link target="_blank" to="https://www.repop-idf.fr/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/repop.png')} /></Link>
                    <Link target="_blank" to="https://www.ifec.net/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/IFEC.jpg')} /></Link>
                    <Link target="_blank" to="https://www.intersport.fr/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/intersport.jpg')} /></Link>
                    <Link target="_blank" to="https://www.fedecardio.org/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/federation-francaise-cardiologie.jpg')} /></Link>
                    <Link><img alt="Logo_Partenaires" src={require('../../images/partenaires/partenaire_kennedy.jpg')} /></Link>
                    <Link><img alt="Logo_Partenaires" src={require('../../images/partenaires/cabane_associations.png')} /></Link> 
                    <Link target="_blank" to="https://www.citeseducatives.fr/"><img alt="Logo_Partenaires" src={require('../../images/partenaires/cites_educatives.jpg')} /></Link>
                    <Link><img alt="Logo_Partenaires" src={require('../../images/partenaires/conference_des_financeurs.jpg')} /></Link> 
                </div>
            </div>
        </>
    );
}

export default Partenaires