import React from 'react'
import Nav from "../../components/Nav";
import navItems from "../../components/Nav/index.json";
import "./index.css"
import subnavItems from "./index.json";

function MuscuSante() {

    return (
        <>
            <Nav items={navItems.urls} />
            <div className="MuscuSante-Core">
            <Nav items={subnavItems.urls} sub />
                <div className="MuscuSante MuscuSante-Intro" id="sport-citoyennete">
                    <p className="MuscuSante-Title">La citoyenneté par le sport au cœur de nos préoccupations</p>
                    <p className="MuscuSante-Text">
                    La spécificité essentielle de notre association est de proposer des actions fortes sur les volets éducatifs, sociaux et citoyens dont l’accompagnement de nos différents publics  dans une pratique sportive bénéfique à leur santé. Et cela dès l’adolescence.<br />
                    En effet, c’est grâce à la pratique sportive que dès le plus jeune âge, chacun fait l’expérience concrète des valeurs de solidarité, de mixité, d’entraide et de tolérance mais aussi de l’effort, du dépassement de soi, de vivre-ensemble et de Fair’Play
                    <br />
                    <img alt="Battle rope Jeunes Extérieur" src={require('../../images/muscu-sante/sport-et-citoyennete_3.jpg')} /><br />
                    Notre Maison sport-santé innove par l’organisation de tables rondes et de séminaires à la fois sur des questions d’hygiène de vie et de santé (portant par exemple sur la lutte contre le dopage, les addictions et la malbouffe ; le bien-être et le sommeil, etc.). Nous menons aussi des projets d’insertions professionnelles par le sport grâce à l’accueil de stagiaires et de services civiques et un travail en collaboration avec les Écoles de la Deuxième Chance du Val de Marne.<br />
                    De nombreux jeunes quittent encore le système scolaire sans formation et sans diplôme et le sport reste parfois leur seul lien avec la société. C’est la raison pour laquelle le rôle du sport est essentiel, que ce soit dans l’éducation à la citoyenneté, mais aussi pour l’emploi et l’insertion professionnelle.
                    <br />
                    <div className="MuscuSante-Twoimg-variante">
                    <img alt="Battle rope Jeunes Extérieur" src={require('../../images/muscu-sante/sport-et-citoyennete.jpg')} />
                    <img alt="Battle rope Jeunes Extérieur" src={require('../../images/muscu-sante/sport-et-citoyennete_2.jpg')} />
                    </div>
                    Par le biais de nos éducateurs sportifs et membres bénévoles du Conseil d’administration, nous avons la volonté de montrer à l’ensemble de nos bénéficiaires, jeunes et moins jeunes, les bienfaits des activités physiques et sportives sur leur santé et leur moral et faisons la promotion de nos activités de lutte contre la sédentarité et l’inactivité physique à l’échelle de toute la ville et de celles du département. Nous intervenons pour cela dans différents Quartiers prioritaires de la politique de la ville dont celui du Mont-Mesly.<br />
                    L’enjeu est de remettre la population en mouvement avec des conseils, un accompagnement et un suivi personnalisé dans le cadre de cette démarche de santé publique.<br /><br />
                    Dans notre quotidien, nous valorisons le « vivre-ensemble », le « faire-ensemble », le partage, la lutte contre les discriminations, le respect, la vie en collectivité, l’inclusion de tous les publics...
                    Lors de leurs inscriptions, les adhérent.e.s de notre association doivent être signataires de la « charte des adhérent.e.s » qui récapitule l’ensemble des règles du vivre ensemble au sein de notre association.<br />
                    Selon notre vision, le sport lorsqu’il relève du monde associatif, doit être utilisé comme un catalyseur du « vivre-ensemble » et de citoyenneté et doit être un outil essentiel de lutte contre les discriminations. Notre équipe travaille à concrétiser cela sur le terrain.
                    </p>
                </div>

                <div className="MuscuSante MuscuSante-Intro" id="sport-feminin">
                    <p className="MuscuSante-Title">La levée des freins à la pratique du sport (-santé) pour les femmes, notamment en quartier populaire</p>
                    <p className="MuscuSante-Text">
                    Historiquement, les femmes ont souvent été dissuadées de participer à des activités sportives en raison de stéréotypes de genres et de normes sociales restrictives. Cela a conduit dès le départ à une disparité de genre dans la participation au sport, avec moins de femmes pratiquant régulièrement une activité physique et sportive que les hommes.<br />
                    Malgré les bienfaits du sport sur la santé, on observe toujours que les femmes sont les plus éloignées de la pratique sportive. En effet, il y a encore de nombreux obstacles qui freinent la pratique féminine : le manque de temps, la difficulté à concilier vie familiale et temps de loisir pour soi, les normes sociales ou culturelles, le manque de confiance en soi, la charge mentale, un budget restreint, les barrières liées à une activité physique ou des installations sportives non adaptées.<br /><br />
                    <strong>Voici une présentation des résultats de notre étude sur les freins à la pratique féminine dans les quartiers populaires de Créteil : </strong><br /><br />
                    <div className="MuscuSante-Twoimg">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/p_p2OiH4qBM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div><br /><br />
                    Notre association a la volonté de favoriser la pratique sportive auprès des féminines et a mis en place de nombreux projets et actions qui permettent d’accueillir et de répondre aux besoins du public féminin.
                    <br />
                        <img alt="Sport féminin" src={require('../../images/muscu-sante/sport_feminin.jpg')} />
                        Depuis 2021, notre association compte plus de bénéficiaires femmes que d’hommes. C’est pour nous une grande réussite pour ne pas dire une grande avancée sociale, mais aussi en terme de citoyenneté, au sein d’une pratique aussi genrée que la musculation. De nombreux partenaires tels que la Fondation de France et la Fédération Française d’Haltérophilie et Musculation nous soutiennent dans le développement de projets pour les féminines.<br /><br />
                        Chez les femmes, la pratique régulière de la musculation santé permet de combattre de nombreuses maladies maladies mais aussi des maladies dits sexo-spécifiques tels que le cancer du sein ou l’ostéoporose qui touchent davantage les femmes de plus de 40 ans.<br /><br />
                        Il est donc important, dans le sport comme ailleurs, de promouvoir l’égalité des sexes et encourager la participation des femmes. Pour ce faire, nous avons développé un écosystème sûr et inclusif à partir de nombreux projets pour les femmes et les filles qui souhaitent participer à nos activités. Ces projets et ces actions dits de « performances sociales » visent à faire des femmes des actrices du développement de notre Maison sport-santé.<br />
                        <a trget="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1JAhviQMowBS49iPJMJK0gkCSycfFEPYgJGoHpLi50bI/edit?usp=sharing">Télécharger notre étude sur la levée des freins à la pratique sportive des femmes</a> <br />
                        <img alt="Sport féminin" src={require('../../images/muscu-sante/sport_feminin_2.jpg')} />
                    </p>
                        
                </div>
                
                <div className="MuscuSante MuscuSante-Intro" id="sport-alimentation">
                    <p className="MuscuSante-Title">L'éducation alimentaire par une approche ludique</p>
                    <p className="MuscuSante-Text">
                    L’alimentation joue un rôle essentiel dans le maintien de notre santé. Toutes les études scientifiques montrent qu’une alimentation équilibrée associée à une activité physique régulière permet de réduire les risques de cancer, de maladies cardiovasculaires, d’hypercholestérolémie, d’ostéoporose, d’obésité et de diabète.<br />
                    Les maladies chroniques liées à la sédentarité et à de mauvaises habitudes alimentaires sont les principales causes de décès dans le monde et peuvent être évitées grâce à des actions de prévention primaire.
                    <br />
                    <img alt="Alimentation" src={require('../../images/muscu-sante/alimentation_3.jpg')} />
                    Depuis plusieurs années, les projets de la Maison Sport-Santé du Mont-Mesly associent la mise en place d’actions pour lutter efficacement contre l’inactivité physique mais aussi pour permettre une meilleure alimentation à ses bénéficiaires. Nous sommes soutenus par la CPAM 94 dans cette démarche.<br />
                    Les nombreux projets voués à la bonne alimentation et à l’activité physique tels que le projet <i>Aimer Bouger, Savoir Manger ; Manger Mieux, Bouger Plus ; le Parcours d’Accompagnement par le Sport pour la Santé l’Émancipation et la Citoyenneté</i> (PASSEC) et le projet CASALIS répondent à des enjeux de santé publique en terme de prévention primaire de maladies que ce soit dans le quartier du Mont-Mesly à Créteil et ailleurs dans le Val-de-Marne.
                    L’objectif de ces projets est de permettre à nos publics d’avoir une meilleure hygiène de vie par le biais de l’activité physique et sportive et d’une meilleure alimentation.
                    <br />
                    Parmi les actions fortes que nous menons et qui font la promotion de la bonne alimentation, citons :<br />
                    <ul>
                        <li>L’apprentissage de la lecture des emballages alimentaires.</li>
                        <li>Des ateliers courses au supermarché.</li>
                        <li>La prévention contre la malbouffe et les addictions.</li>
                        <li>Des ateliers de cuisine.</li>
                        <li>Des buffets équilibrés.</li>
                        <li>La mise en place de jardins partagés et des conseils de jardinage en milieu urbain pour cultiver ses propres fruits et légumes.</li>
                        <li>Des sorties pour des cueillettes dans des exploitations agricoles.</li>
                    </ul>
                    A cela s’ajoute nos actions en terme de développement durable pour sensibiliser nos publics à des enjeux planétaires que ce soit en matière d’alimentation, de préservation des ressources, d’environnement, d’écologie, etc… <br />
                    <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1GeQrl5SJDYV9mt4Srrmu7ErjDlY3Ujuw/view">Pour en savoir plus sur notre projet "Manger mieux, Bouger plus dans nos quartiers"</a>
                    <img alt="Alimentation" src={require('../../images/muscu-sante/stand_alimentation.jpg')} />
                    <img alt="Alimentation" src={require('../../images/muscu-sante/alimentation.jpg')} />
                    </p>
                </div>

                <div className="MuscuSante MuscuSante-Intro" id="jardiner-sante">
                    <p className="MuscuSante-Title">Jardiner-santé : de la dynamisation physique au bien-être mental.</p>
                    <p className="MuscuSante-Text">
                    Le jardinage est l’un de des loisirs préférés des français. C’est une activité qui a de nombreux bénéfices : <br />
                    <ul>
                        <li>Elle permet de renouer avec la nature.</li>
                        <li>Elle permet de faire pousser ses propres fruits et légumes, par plaisir ou par nécessité.</li>
                        <li>Elle contribue à la préservation de la biodiversité par conscience écologique.</li>
                        <li>Elle contribue à se libérer du stress quotidien et à la préservation de la santé mentale (horticothérapie)</li>
                        <li>Elle permet d’avoir une activité socialisante et bénéfique au faire-ensemble et au vivre-ensemble.</li>
                        <li>Elle permet de bouger davantage au quotidien et de se dépenser sans y penser et ainsi de lutter contre les effets délétères de la sédentarité.</li>
                    </ul>
                    La Maison sport-santé de Créteil/Mont-Mesly a innové en développant des activités physiques de jardinages qui font la promotion de la bonne alimentation, de l’activité physique et de la prévention aux bonnes postures.<br />
                    <img alt="Jardinage" src={require('../../images/muscu-sante/jardinage1.jpg')} /><br />
                    Nous attachons en effet une importance essentielle aux échauffements, à la bonne manière de bouger et de porter des charges à des fins de prévention du mal de dos par exemple ou pour limiter l’apparition des troubles musculo-squelettiques.<br />
                    Le développement de l’agriculture urbaine est d’ailleurs l’une des ambitions du <a target="_blank" rel="noopener noreferrer" href="https://agriculture.gouv.fr/francerelance-lancement-de-lappel-projets-jardins-partages">Ministère de l’agriculture</a>.
                    <br /><strong>Cette activité se doit de remettre « l’alimentation au cœur des enjeux de notre société, la crise sanitaire a suscité un nouvel élan de nos concitoyens pour les circuits courts. Le développement de jardins associatifs et d'agriculture urbaine s’en est trouvé renforcé et le Plan de relance veut entretenir cette dynamique. »</strong><br />

                    Nous intervenons dans cette optique dans tout type de structures (centres sociaux, centres de formations, écoles, associations, entreprises…) pour développer de micro-potagers urbains.<br />
                    C’est notamment <a target="_blank" rel="noopener noreferrer" href="https://www.18h39.fr/articles/avec-son-micro-potager-urbain-100-ecolo-il-a-recolte-plus-de-300-kilos-de-fruits-et-legumes.html">Irfaan Burahee</a>, directeur de notre structure, docteur en géographie et spécialiste en agriculture urbaine qui interviendra dans votre structure pour des ateliers en jardinage-santé afin de favoriser le bien être physique et mental et la cohésion d’équipe de vos bénéficiaires ou collaborateurs.<br />

                    <img alt="Jardinage" src={require('../../images/muscu-sante/jardinage2.jpg')} />
                    Parallèlement à cette activité de jardinage santé, nous innovons en organisant des ateliers de cueillettes afin de sensibiliser nos publics à une bonne alimentation avec des gestes éco-réponsables (consommer des fruits et légumes de saison, favoriser les circuits courts, tri des déchets à des fins de compostages, etc…).<br />
                    Contacter nous via le formulaire de contact pour plus de renseignements.
                    <img alt="Cueillettes" src={require('../../images/muscu-sante/ceuillettes.jpg')} />
                </p>
                </div>
                <div className="MuscuSante MuscuSante-Intro" id="sport-travail">
                    <p className="MuscuSante-Title">Contribuer au développement du sport-santé au travail</p>
                    <p className="MuscuSante-Text">
                    Faire du sport au travail (en entreprise, en collectivité publique et en association), c’est tout d’abord profiter des bienfaits liés à la pratique d’une activité physique au quotidien.<br />
                    Les bénéfices pour les participants sont nombreux : meilleure forme physique, baisse du stress, prévention des troubles musculo-squelettiques (TMS), hausse de la productivité et de la concentration…
                    <br/> 
                    Le développement du sport au travail permet aussi une meilleure conciliation des temps de vie : le salarié, qui n’a pas toujours la possibilité de faire du sport le soir, peut pratiquer une activité sportive en journée, directement sur son lieu de travail ou à proximité.<br />
                    L’organisation d’activités collectives renforce par ailleurs les relations entre collègues, favorise l’entraide, la cohésion et l’esprit d’équipe. Cette amélioration du climat social en milieu professionnel profite aussi à l’employeur, dans une dynamique gagnant-gagnant.
                    <br /><a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1V19AywwWCdOzcq_Jij6fVbnPLBPneSLB/view?usp=sharing">Notre brochure sport-santé au travail</a>
                    <img alt="Santé en milieu professionnel" src={require('../../images/muscu-sante/sante-milieu-pro-2.jpg')} />
                    <strong>Problématique et offre de service :</strong><br />
                    Les nombreuses heures passées assis face à son ordinateur et dans les transports engendrent de nombreuses contraintes musculaires et articulaires dans les régions du dos, du bassin, des épaules et des cervicales. Pour participer à l’amélioration du bien-être salarial, nous proposons des séances de Postural Training en milieu professionnel, en extérieur ou en intérieur, avec éducateurs sportifs formés en Sport-Santé. En effet, la Maison sport-santé de Créteil/Mont-Mesly vous propose une activité physique innovante et ludique qui se pratique avec du petit matériel de fitness et intègre, dans notre approche, des exercices de renforcement issus de la musculation et de l’haltérophilie.<br /><br />
                    <span className="MuscuSante-SectionTitle">Le Postural Training, c’est quoi ?</span><br />
                    Le Postural Training ou renforcement postural vise à la fois à renforcer et étirer les muscles. 
                    <br />Le Postural Training ou renforcement postural vise à la fois à renforcer et étirer les muscles.
                    Il s’agit de centrer principalement les exercices sur les muscles posturaux du dos (muscles fixateurs des omoplates et érecteurs du rachis), des épaules (deltoïdes postérieurs et rotateurs externes), des fessiers profonds (muscles pelvi-trochantériens), du psoas (muscle fléchisseur de la hanche), et des ischio-jambiers (muscles extenseurs de la hanche). Le cou et la nuque sont aussi pris en compte, notamment dans les entreprises où l’activité bureautique ou digitalisée est dominante.
                    <br /><br />
                    <span className="MuscuSante-highlight">Objectif de l’activité :</span><br />
                    Cette activité physique vise à renforcer et à tonifier l’ensemble des muscles du corps, et à améliorer les capacités cardio-respiratoires.<br />
                    Pratiqué régulièrement dans un cadre sécurisé, le Postural Training apporte du bien-être et permet de lutter et de prévenir le mal de dos, les TMS et d’autres pathologies.
                    <br /><br />
                    <span className="MuscuSante-highlight">Déroulé des ateliers :</span><br />
                    D’une durée de 45 minutes cet atelier offrira aux participant.e.s un entraînement complet permettant une amélioration de sa condition physique, de son état de santé général tout en apportant du bien-être.<br />
                    Des exercices d’étirements sont réalisés à chaque séance afin d’améliorer la souplesse pour un meilleur confort articulaire. Plusieurs exercices contribueront à la santé de votre colonne vertébrale afin de vous permettre une bonne posture dans la durée.<br /><br />
                    N’hésitez pas à nous demander plus de renseignements sur nos activités de sport-santé au travail ou pour une demande de devis via le formulaire de contact sur l’onglet Infos utiles.
                    </p>
                    <img alt="Postural Training" src={require('../../images/muscu-sante/postural-training.jpg')} />
                </div>

                <div className="MuscuSante MuscuSante-Intro" id="sport-sante">
                    <p className="MuscuSante-Title">Rendre accessible la pratique de la musculation santé à tous les publics</p>
                    <p className="MuscuSante-Text">
                    La musculation est l’une des activités physiques et sportives les plus pratiquées en France.
                    L’US Créteil HMF-Maison sport-santé du Mont-Mesly est affiliée à la Fédération Française d’Haltérophilie et Musculation depuis plusieurs décennies.<br />
                    Cette fédération olympique a développé des formations, un cadre et des contenus spécifiques au sport-santé : il s’agit du Coach Muscu Santé.<br />
                    La musculation adaptée est depuis longtemps utilisée dans un contexte médical en soins de suite et de réadaptation dans les hôpitaux pour aider les patients à retrouver de l’autonomie.<br />
                    Elle est utilisée en soins de support dans les services d’oncologie pour aider les patients à combattre le cancer et supporter certains traitements lourds (chimiothérapie).<br />
                    En cabinet de kinésithérapie, elle est utilisée à des fins fonctionnelles, de rééducation, de reconditionnement physique et autres prises en charge spécifiques.
                    <div className="MuscuSante-Twoimg">
                        <img alt="Musculation Santé" src={require('../../images/muscu-sante/muscu_sante.jpg')} />
                        <img alt="Musculation Santé" src={require('../../images/muscu-sante/muscu_sante_2.jpg')} />
                    </div>
                    <br />
                    Les avantages de la musculation sont nombreux, elle améliore les effets bénéfiques de la pratique d’activités physiques et sportive car elle permet :
                    <ul>
                        <li>Une approche totale du corps humain grâce à un répertoire gestuel de mouvements qui concerne l’ensemble des muscles du corps.</li>
                        <li>Une possibilité d’adapter l’amplitude des mouvements pour s’adapter aux capacités de chacun tout en développant la souplesse et la mobilité.</li>
                        <li>Une montée progressive dans les charges proposées.<br />La recherche de l’intensité est bénéfique aux améliorations métaboliques (renforcement musculaire).<br />Les charges plus légères (circuit training) améliorent la qualité d’endurance et donc la condition physique.</li>
                        <li>La graduation des efforts en durée afin de cibler le développement de certaines qualités physiques.</li>
                    </ul>
                    <br />
                    Il est possible de répertorier quatre apports essentiels de la musculation sur la santé :
                    <ul>
                        <li>Tonification de la posture et soulagement des douleurs dorsales par le renforcement des abdominaux, des muscles du rachis et des lombaires.</li>
                        <li>Amélioration de la mobilité articulaire et de la souplesse musculaire grâce à un entraînement complet et équilibré qui travaille l’ensemble des muscles (agonistes et antagonistes).</li>
                        <li>Renforcement des qualités d’équilibre grâce au travail de sensation musculaire (proprioception) et d’utilisation des mouvements polyarticulaires (coordination).</li>
                        <li>Recul de la fonte musculaire par le recours aux exercices de résistance adaptée à chacun. La force et le volume musculaire se développent et repoussent l’âge de la dépendance.</li>
                    </ul>
                    
                    <br />
                    La musculation est reconnue scientifiquement par le monde médical pour ces apports bénéfiques dans cinq types de maladies :
                    <ul>
                        <li>Les maladies métaboliques : diabète, obésité.</li>
                        <li>Les maladies cardiovasculaires</li>
                        <li>Les cancers.</li>
                        <li>Les maladies dues aux effets du vieillissement : sarcopénie, ostéoporose.</li>
                        <li>Les maladies neuro-dégénératives : Sclérose En Plaque, Parkinson, Alzheimer.</li>
                    </ul>
                    <br />
                    Dans une optique sport-santé, vous pourrez retrouver le contenu fédéral de la Muscu’Santé dans le <a target="_blank" rel="noopener noreferrer" href="https://cnosf.franceolympique.com/cnosf/fichiers/File/Medical/Medicosport/medicosport-version-internet-v2.pdf">médico sport-santé</a>.
                    <br /><br />
                    <div className="MuscuSante-Twoimg">
                    <iframe width="500" height="315" src="https://www.youtube.com/embed/hdPP23ysZVg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>

                    </p>
                </div>
            </div>
        </>
    );
}

export default MuscuSante
