import './App.css';
import Contact from "./pages/Contact";
import Mss from "./pages/Mss";
import Activites from "./pages/Activites";
import Partenaires from "./pages/Partenaires";
import Adhesions from "./pages/Adhesions";
import MuscuSante from "./pages/MuscuSante";
import Accueil from "./pages/Accueil";  
import {
  Route,
  Routes,
  HashRouter
} from "react-router-dom";
import React, { } from 'react';


function App() {

  return (

      <HashRouter>
        <div className='App'>
          <Routes>
            <Route path="/" element={<Accueil/>} />
            <Route path="/maison-sport-sante" element={<Mss />} />
            <Route path="/partenaires" element={<Partenaires />} />
            <Route path="/muscu-sante" element={<MuscuSante />} />
            <Route path="/adhesions" element={<Adhesions />} />
            <Route path="/infos-utiles" element={<Contact/>} />
            <Route path="/nos-activites" element={<Activites />} />
          </Routes>
        </div>
      </HashRouter>
    ) 

}

export default App;