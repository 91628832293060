import "./index.css"
import React, {} from 'react'
import Nav from "../../components/Nav";
import navItems from "../../components/Nav/index.json";

function Accueil() {

    /*
        - Ajouter 2 logos, photos gymnase, texte maison sport santé
        - https à vérifier
        - Corriger soucis onglet nav souligné écart
        - logo hiérarchie
        - photo page contact gymnase
    */
    
const isMobile = window.matchMedia("(max-width: 45.5em)").matches;
    return (
        <>
        <Nav items={navItems.urls}/>
        <div className="Accueil-Core">

            <div className="Accueil-Pictures">
                <span><img alt="Gymnase cours femme" src={require('../../images/logo_MSS.png')} /></span>
                { isMobile ? <span><img alt="Gymnase Paul Casalis - Maison Sport-Santé de Créteil Mont/Mesly - Cours femme Circuit-Training musculation" src={require('../../images/partenaires/Haltéro_musculation.png')} /></span> : ""}
                <span className="Accueil-Intro">
                « <a target="_blank" rel="noopener noreferrer" href="https://www.sports.gouv.fr/les-maisons-sport-sante-un-outil-d-egalite-des-chances-et-d-acces-au-droit-de-la-sante-par-le-sport"><strong>Les Maisons Sport-Santé</strong></a> sont un outil d'égalité des chances et d'accès au droit de la santé par le sport.<br />
                Créées en 2019 par les ministères des Sports et de la Santé, elles répondent à un objectif national de santé publique pour lutter contre les effets délétères de la sédentarité. » <br />
                </span>
                { !isMobile ? <span><img alt="Gymnase Paul Casalis - Maison Sport-Santé de Créteil Mont/Mesly - Cours femme Circuit-Training musculation" src={require('../../images/partenaires/Haltéro_musculation.png')} /></span> : ""}
            </div>
            <div className="Accueil-Main-Picture">
                <img alt="Gymnase cours femme" src={require('../../images/gymnase_cours_femme.jpg')} />
            </div>
            <div className="Accueil-Accroche">La Maison Sport-Santé de Créteil/Mont-Mesly développée par l'Union Sportive de Créteil Haltérophilie-Musculation et Fitness vous propose des activités sportives adaptées à votre condition physique dans un écosystème ouvert et inclusif.</div>
            <div className="Accueil-Main-Picture">
                <iframe width="80%" height="500" src="https://www.youtube.com/embed/YPziiZYUyTg?si=n7bsGbKpFHzOxcyn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div><br />
            {/*<div className="Accueil-Highlights-Intro">Nos missions</div>*/}
            <div className="Accueil-Highlights">
                {/*<div><span>Vous informer</span> <br />Sur les bienfaits d'une activité physique et sportive adaptée à votre santé</div>
                <div><span>Vous orienter</span> <br />Vers des professionnels de l'activité physique et sportive adaptée</div>
                <div><span>Vous accueillir</span> <br />Pour une pratique sportive adaptée à votre état de santé avec des bilans de forme réguliers</div>
                <div><span>Vous accompagner</span> <br />Dans une pratique sportive durable bénéfique pour votre santé</div>*/}
                <img alt="Les missions de notre Maison Sport-Santé de Créteil/Mont-Mesly" src={require('../../images/missions.png')} />
                <p>Cliquez <a href="https://drive.google.com/file/d/1OvU4EM1AcqdMqwQ2_WeC0HVsf95ZjUIn/view?usp=sharing">ici</a> pour en savoir plus sur la Stratégie Nationale Sport-Santé</p>
            </div>
            
            
        </div>
        </>
    )
}

export default Accueil;