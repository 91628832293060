import React from 'react'
import "./index.css"
import {
    NavLink,
    Link,
    useLocation
} from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

function Nav(props) {

    const { items, sub, modifiers } = props;
    const itemKeys = Object.keys(items);
    console.log(itemKeys);
    const isModifiers = modifiers ? modifiers : "";
    const isMobile = window.matchMedia("(max-width: 45.5em)").matches;

    const toggleNav = () => {
        const Nav = document.getElementById('Nav');
        Nav.classList.toggle('Nav-hide');
        const NavLinks = document.getElementById('Nav-Links');
        if(!NavLinks.classList.contains('moved') && !NavLinks.classList.contains('unmoved')) {
             NavLinks.classList.toggle('moved');
        }
        else if (NavLinks.classList.contains('moved')) {
            NavLinks.classList.toggle('moved');
            NavLinks.classList.toggle('unmoved');
        }
        else {
            NavLinks.classList.toggle('moved');
            NavLinks.classList.toggle('unmoved');
        }
    }
    const { hash } = useLocation();
    const isActive = (iHash) => hash === iHash;

    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -60; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return (
        <div id={!sub ? "Nav" : "SubNav"} className={!isMobile && !sub ? "Nav MainNav" + isModifiers : !isMobile && sub ? "Nav " : 'Nav Nav-hide ' + isModifiers}>
            { !sub && <Link to="/" className="MSS_Logo"><div className='App-Logo'><img alt="Logo_Maison_sport_santé" src={require('../../images/logo_MSS.png')}/></div></Link>}
            {isMobile && <div id="Nav-mobile-menu"><span className='Nav-mobile-menu-icon' onClick={toggleNav}></span>
                <div id="Nav-Links">
                    {/*<Link to="/" className="MSS_Logo LogoMobile"><div className='App-Logo'><img alt="Logo_Maison_sport_santé" src={require('../../images/logo_MSS.png')}/></div></Link>*/}
                    {itemKeys.map((item) => (
                    <NavLink onClick={isMobile && toggleNav} key={item} to={items[item]} id={item} className={"Nav-item " + isModifiers} end>
                        {item}
                    </NavLink>
                    ))}
                    <div className='FB-Logo LogoMobile'><Link target="_blank" to="https://www.facebook.com/Maison.Sport.Sante.Creteil.MontMesly/"><img alt="Facebook Logo" src={require('../../images/facebook_logo.png')}/></Link></div>
                    <div className='Insta-Logo LogoMobile'><Link  target="_blank" to="https://www.instagram.com/gymnase_paul_casalis_uschmf/"><img alt="Instagram Logo" src={require('../../images/instagram_logo.png')}/></Link></div>
                    <div className='Tiktok-Logo'><Link target="_blank" to="https://www.tiktok.com/@uschmf?_t=8it9ODTykyH&_r=1"><img alt="Tiktok Logo" src={require('../../images/tiktok_logo.png')}/></Link></div>
                    <div className='Docto-Logo'><Link target="_blank" to="https://www.doctolib.fr/maison-sport-sante/creteil/us-creteil-hm-maison-sport-sante-du-mont-mesly"><img alt="Doctolib Logo" src={require('../../images/doctolib_logo.png')}/></Link></div>
                </div>
            </div>}
            <div className={!sub ? "Nav-Core " : 'Nav-Core SubNav'}>
                {!isMobile && itemKeys.map((item) => (
                !sub ? <NavLink onClick={isMobile && toggleNav} key={item} to={items[item]} id={item} className={"Nav-item" + isModifiers} end>
                    {item}
                </NavLink>
                : 
                <NavHashLink style={
          isActive(items[item])
            ? {
                color: "black"
              }
            : {
                border: 0,
                color: "black",
                opacity: 1
            }
        }
                  smooth key={item} to={items[item]} id={item} scroll={scrollWidthOffset} className={"Nav-item" + isModifiers} end>
                {item}</NavHashLink>
            ))}
            </div>
            { !sub && <div className='RS-Logos FB-Logo'><Link target="_blank" to="https://www.facebook.com/Maison.Sport.Sante.Creteil.MontMesly/"><img alt="Facebook Logo" src={require('../../images/facebook_logo.png')}/></Link></div>}
            { !sub && <div className='RS-Logos Insta-Logo'><Link  target="_blank" to="https://www.instagram.com/gymnase_paul_casalis_uschmf/"><img alt="Instagram Logo" src={require('../../images/instagram_logo.png')}/></Link></div>}
            { !sub && <div className='RS-Logos Tiktok-Logo'><Link target="_blank" to="https://www.tiktok.com/@uschmf?_t=8it9ODTykyH&_r=1"><img alt="Tiktok Logo" src={require('../../images/tiktok_logo.png')}/></Link></div>}
            { !sub && <div className='RS-Logos Docto-Logo'><Link target="_blank" to="https://www.doctolib.fr/maison-sport-sante/creteil/us-creteil-hm-maison-sport-sante-du-mont-mesly"><img alt="Doctolib Logo" src={require('../../images/doctolib_logo.png')}/></Link></div>}
        </div>
    )

}

export default Nav; 