import React from 'react'
import Nav from "../../components/Nav";
import navItems from "../../components/Nav/index.json";
import subnavItems from "./index.json";
import "./index.css"

function Mss() {

    return (
        <>
            <Nav items={navItems.urls} />
            <Nav items={subnavItems.urls} sub />
            <div className="MSS-Core">
                <div className="MSS MSS-Intro" id="maison">
                    <p className="MSS-Title">La Maison Sport-Santé de Créteil/Mont-Mesly</p>
                    <p className="MSS-Text">
                    Implantée en plein cœur du Mont-Mesly à Créteil, notre association propose un panel d'activités liés aux sports de la forme et du bien-être. <br />
                    La Maison sport-santé de Créteil/Mont-Mesly a été inaugurée en 2021, elle est portée par l'Union Sportive de Créteil Haltérophilie, Musculation et Fitness. Cette association a été fondée en 1979 et est membre de l’Union Sportive de Créteil générale qui est l’une des plus importantes fédérations d’associations sportive de France.<br />
                    Nous accueillons nos adhérent.e.s et nos bénéficiaires du lundi au samedi au sein de la salle de musculation du centre sportif du Docteur Paul Casalis.<br />
                    
                    <a href="https://docs.google.com/document/d/1g2iSb720_wZCzTYOxlD-UvrK2QZE_n68d0-KE6DS2mI/edit?usp=sharing" rel="noopener noreferrer" target="_blank">Télécharger notre dossier de presse</a>
                    </p>
                </div>
                <div className="MSS MSSImg MSSImgRight">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/ILd1vCqx9Io" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            </div>

            <div className="MSS-Core">
                <div className="MSS MSSImg MSSImgLeft">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/6gLOc76VTpU?start=4904" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
                <div className="MSS MSS-Intro">
                    <p className="MSS-Text">
                        Nous proposons aussi des ateliers de sport-santé aux associations, aux entreprises, aux hôpitaux, aux EHPAD et à toutes autres structures. En salle de sport, en extérieur et au sein même de vos locaux. <br />Notre objectif est simple : aider au maintien et tendre vers une amélioration de la santé et de la qualité de vie par une pratique sportive adaptée, efficace et sécurisée.<br />
                        Les objectifs des ateliers en activités physiques et sportives sont définis avec le bénéficiaire et avec les professionnels de la santé et du sport qui l'entourent.<br />
                        Plusieurs sports sont principalement utilisés à des fins de santé : la musculation, l’haltérophilie, le fitness et la marche posturale.
                    </p>
                </div>

            </div>

            <div>

                <div className="MSS MSS-Intro" id="sport-sante">
                    <p className="MSS-Title">Le sport-santé c’est quoi ? </p>
                    <p className="MSS-Text">
                        Le « <strong><a target="_blank" rel="noopener noreferrer" href="https://www.sports.gouv.fr/pourquoi-promouvoir-la-sante-par-le-sport-393">sport-santé</a></strong> » recouvre la pratique d’activités physiques ou sportives permettant de favoriser le bien-être et la santé du pratiquant conformément à la définition de la santé par l’Organisation Mondiale de la Santé (OMS) : physique, psychologique et sociale.<br /><br />
                        En prévention primaire, la pratique d’activités physiques ou sportives contribue au maintien de la santé. En prévention secondaire, elle a pour but chez les personnes vivant avec une maladie chronique ou une affection longue durée à améliorer leur état de santé. En prévention tertiaire, la pratique du sport-santé aide à prévenir l’aggravation et/ou la récidive de maladies.<br /><br />
                        Le sport-santé peut se pratiquer sur les recommandation d’un médecin dans le cas d’une affection de longue durée par exemple, c’est le dispositif du Sport sur Ordonnance.
                    </p>
                </div>
                <div className="MSS-ImgCentered">
                    <img alt="Les bienfaits du Sport-Santé" src={require('../../images/bienfaits.JPG')} />
                </div>
                <br /><br />
            </div>

            <div>
                <div className="MSS MSS-Intro" id="public">
                    <p className="MSS-Title">Notre public</p>
                    <p className="MSS-Text">
                    Le public auquel nous nous adressons est varié : les jeunes adolescents et adultes, les seniors, les personnes atteintes de maladies chroniques ou d’ALD, les personnes souffrant d'obésité ou en surpoids.<br /><br />
                    Nos activités physique et sportives sont accessibles à tous. Elles peuvent se pratiquer selon différents niveaux d’intensité, adaptés à votre rythme et vos envies. Notre but est de vous aider à lutter contre les effets délétères de la sédentarités et de l’inactivité physique. Les recommandations de l’Organisation Mondiale de la Santé pour être en bonne santé sont claires : les adultes devraient pratiquer 3 à 5 fois par semaine une activité physique d’endurance à intensité modérée, soit 30 minutes par jour (2h30 en cumulée par semaine). Chez les enfants et les ados, c’est le double, soit 60 minutes par jour.<br /><br />
                    Nous proposons à nos publics des activités physiques et sportives pouvant être pratiquées seul ou à plusieurs, dès l’âge de 12 ans.<br />
                    A tous les âges de la vie, l’activité physique et sportive a de nombreux bienfaits physique, mental et social. Grâce a elle, il est possible de se réaliser ; de lutter contre les effets néfastes de la sédentarité et rechercher du bien-être ; de développer son autonomie, sa confiance en soi et ses aptitudes physiques ; de partager des moments conviviaux et d’être en meilleure santé.<br />
                    Nous vous accompagnerons pour pouvoir mieux vivre, plus longtemps et en bonne santé.
                    </p>
                </div>
                <div className="MSS-ImgCentered">
                    <img alt="Le public de notre Maison Sport-Santé du Mont-Mesly" src={require('../../images/public_MSS.png')} />
                </div>
                <br /><br />
            </div>

            <div id="team">

                <div className="MSS MSS-Intro" id="equipe">
                    <p className="MSS-Title">Notre équipe</p>
                    <p className="MSS-Text">
                    Que ce soit pour une prise en charge en prévention primaire ou en prévention secondaire ou tertiaire (sport sur ordonnance), notre équipe composée de personnes formées à un accompagnement spécifique des différents publics saura mettre en œuvre son expertise au service de votre santé.
                    <br />
                    Des profils variés allant de l'enseignant en APA (Activités Physiques Adaptés) au BPJEPS AF, nos éducateurs sont spécialisés en musculation. 
                    <br /> Ils sont détenteurs du diplôme fédéral coach Muscu-santé. 
                    Ils mettront leurs connaissances et leur expertise au service de votre santé et de votre bien-être.<br />
                    Nos projets sport-santé sont pilotés par un comité d'experts bénévoles et salariés composé d’un directeur sportif, d’une chargée de développement, de spécialistes médicaux et de chercheurs.
                    </p>
                </div>
                <div className="MSS MSSImg MSSImgLeft">
                    <img alt="Notre équipe à la Maison Sport-Santé du Mont-Mesly" src={require('../../images/MSS_equipe.png')} />
                </div>
            </div>

            <div className="MSS-Core MSS-Staff">
                <div className="MSS MSS-Intro">
                    <p className="MSS-SubTitle">
                        Julien Barbe, Président de l’US Créteil HMF/Maison sport-santé du Mont-Mesly et professeur d’EPS.
                    </p>
                    <p>
                        Julien s’implique depuis plusieurs années dans le développement des projets sportifs de l’association, notamment des projets sportifs en lien avec la santé des jeunes et la sédentarité numérique. <br/>
                        C’est un passionné de musculation et compétiteur dans l’âme. Il a été champion de France des Muscu’Challenges et vice-champion de France de Musculation FFHM en 2022 et plusieurs fois champion régional de musculation.
                    </p>
                    <p className="MSS-SubTitle">
                        Irfaan Burahee, directeur administratif et sportif.
                    </p>
                    <p> 
                        Irfaan est docteur en géographie du développement et conférencier. <br/>
                        Ancien président du club (2012 et 2013) et actuellement membre de la commission nationale Musculation, santé et bien-être à la FFHM, Irfaan est l’initiateur du projet de la Maison sport-santé de Créteil/Mont-Mesly et de nombreux autres projets en lien avec la citoyenneté et le sport. <br/>
                        Il développe aussi de nombreuses actions sur les thèmes de l’alimentation, le sport et le développement durable. Passionné lui aussi de musculation, il a été plusieurs fois champion régional de musculation et d’haltérophilie.
                    </p>
                    <p className="MSS-SubTitle">
                        Linda Bouifrou, chargée du développement et des partenariats de la Maison sport-santé, patiente experte, éducatrice ETP et chercheure.
                    </p>
                    <p>
                        Linda a été de 2014 à 2022 la présidente de l’association. Elle a récemment mis en place le pôle innovation et performances sociales au sein de notre structure. <br/>
                        En collaboration avec des universités de France et ailleurs dans le monde, elle travaille activement sur les questions liées au genre, notamment de la place des femmes dans le sport et dans la société. <br/>
                        Linda a permis l’émergence de projets innovants pour les publics les plus éloignées de la pratique sportive telles que les jeunes filles, les féminines avec une ALD et les seniors.
                    
                    </p>
                    <p className="MSS-SubTitle">
                        Muhammed Burahee, agent de développement sport-santé.
                    </p>
                    <p>
                        Il a commencé au sein de notre structure en tant que secrétaire général.<br />
                        Passionné de musculation et d’haltérophilie, il est actuellement responsable des projets sport-santé et numérique. <br/>
                        Ce sera votre principal interlocuteur lors de votre inscription au club pour vous orienter au mieux au sein de nos différentes activités.
                    </p>
                </div>
                <div className="MSS MSSImg MSSImgRight MSS-Staff">
                <p className="MSS-SubTitle">
                    Frédéric Alpha, éducateur sportif et préparateur physique. 
                </p>
                <p>
                    Frédéric Alpha est diplômé d'un BPJEPS AF et du diplôme fédéral Coach Muscu'Santé de la FFHM. 
                    Il est responsable des cours de cross-training en extérieur du projet « Bouger à Créteil pour sa santé ». <br/> 
                    Il attache une importance particulière à utiliser la musculation comme levier de développement personnel de ses athlète et est également péparateur physique pour des basketteurs.
                </p>
                <p className="MSS-SubTitle">
                    Nicolas Coeuret, Enseignant en activités physiques adaptées et santé.
                </p>
                <p> 
                    Il intervient dans plusieurs de nos projets dont Seniors t’es fort et en milieu hospitalier pour des soins de supports en pôle oncologie. <br/> 
                    Nicolas souhaite davantage démocratiser l’APA pour faire profiter de ses bienfaits à un maximum de public. <br />Sur le plan sportif, Nicolas est un passionné de sport de raquettes et surtout de football. 
                </p>
                <p className="MSS-SubTitle">
                    Bastien Huet, éducateur sportif sport-santé. 
                </p>
                <p>
                    Spécialisé dans la pratique sport-santé chez les jeunes des quartiers populaires, il est l’un des rares éducateurs sportifs en France a avoir été formé au Module Ados en Forme de la FFHM. <br/> 
                    Il est actuellement référent sur le projet Muscu’Jeunes, le projet Musculation santé/cardio et intervient chez nos nombreux partenaires pour le sport-santé au travail. <br/> 
                    Bastien est aussi un passionné de musculation et un compétiteur. <br/> 
                    Il a été plusieurs fois champion régional de musculation.
                </p>

                <p className="MSS-SubTitle">Nos autres intervenants : </p>
                <p>Des patients experts, infirmières, médecins, psychologue, chiropracteurs, intervenant.e.s en arts thérapies et autres spécialités interviennent au sein de de nos activités en fonction de nos actions et de nos besoins.<br />
                Des <a target="_blank" rel="noopener noreferrer" href="https://www.service-civique.gouv.fr/">services civiques</a> et des stagiaires de l'<a href="https://sess-staps.u-pec.fr/" target="_blank" rel="noopener noreferrer">UPEC</a> et d'autres universités, de la filière STAPS APA accompagnent notre équipe sur toutes nos actions au gymnase Paul Casalis et en extérieur chez nos partenaires.
                Nous menons ainsi un rôle de facilitateurs et d'accompagnateur vers l'insertion professionnelle de ces jeunes au sein de notre Maison Sport-Santé.
                </p>
                </div>
            </div>
            

            

            

            

            

            

        </>
    );
}

export default Mss